import React from "react";
import { Link } from 'react-router-dom';
import "./all.css";
import { shuffle } from "lodash";
import { useEffect, useState } from "react";
import NotifyForm from './NotifyForm';
import { Header } from './Header';
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const About = () => {
  const [tagsdata, setTagsdata] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const handleNotifyClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };
  useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []); 

  return (
    <div className="App">
    <Header />
    <div  className= 'Trainingpage' >
    <div class="row">
     <div class="col-md-2"></div>
     <div class="col-md-8">
        <h4>Master Python with Expert Guidance: Dynamic Online Training with Real Instructors</h4>
        <br /><p>One of the notable advantages of online Python training with a real trainer is the opportunity to engage in live sessions where participants can interact directly with experienced instructors. </p>

      <p>Embarking on a journey to master Python programming is an exciting endeavor, and opting for online training with a real trainer can make the learning experience more engaging and effective. Online Python training programs with live instructors offer a dynamic and interactive environment, ensuring that learners receive personalized guidance throughout their coding journey.</p>
     <h5>Coming Soon!!!</h5>
      <Link to="#" onClick={handleNotifyClick} className="notify-link">
        Notify Me
      </Link>

      {showForm && <NotifyForm onClose={handleCloseForm} />}
      </div>
    </div>
   </div>
   <div className="container-footer">
        <footer>
          {/*<div>
            <p>copyright@9faqs.com</p>
          </div>*/}
          <span className="python-tags"> Tags:
          </span>
          {tagsdata[1]?.tags?.map((tag_name, i) => (
            <span className="python-tags"
              key={tag_name.id}
            >
              {tag_name.name}
              {i < tagsdata[1].tags.length - 1 ? " | " : null}
            </span>
          ))}
         <div></div>
          <br />
        <div className="footer-about">
        <a className="footer-about" href='/about'>About &nbsp;</a>  |
        <a className="footer-about" href='/feedback'>&nbsp;  Feedback</a> | copyright@9faqs.com
        </div>
         <div className="social-media-icons">
          <a href="https://www.facebook.com/9FAQs" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/9faqs_official/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://x.com/9faqs" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
        </footer>
      </div> 
   </div>
  );
};

export default About;
