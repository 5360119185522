// NotifyForm.js
import React, { useState } from 'react';

const NotifyForm = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [remarks, setRemarks] = useState('');
  const [address, setAddress] = useState('');
  const [trainingType, setTrainingType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Handle form submission (e.g., send email, save to database)
    console.log('Form submitted:', { name, email, remarks, address, trainingType });
    // Close the form after submission
    onClose();
  };

  return (
    <div className="notify-form">
      <h2>Please fill the below form to submit the intrest</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="remarks">Remarks:</label>
        <textarea
          id="remarks"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          required style={{ width: '100%' }}
        />

        <label htmlFor="address">Address:</label>
        <textarea
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required style={{ width: '100%' }}
        />

        <label htmlFor="trainingType">Type of Training:</label>
        <select
          id="trainingType"
          value={trainingType}
          onChange={(e) => setTrainingType(e.target.value)}
          required
        >
          <option value="">Select Type</option>
          <option value="fitness">Python Basics</option>
          <option value="programming">Complete Python Training</option>
          {/* Add more options as needed */}
        </select>
        <div style={{ marginBottom: '15px' }}></div> 
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default NotifyForm;

