import React from "react";
import "./all.css";
import axiosInstance from "../axioApi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faPlus, faHome, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
// import Home from "./components/home";
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { shuffle } from "lodash";
//import { Text } from "react-native";
import ProfileDropdown from '../ProfileDropdown';
import Login from './Auth';

export const Header = () => {
  /* async function handleLogout() {
    try {
      const response = await axiosInstance.post("/blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      console.log(localStorage);
      axiosInstance.defaults.headers["Authorization"] = null;
      console.log(axiosInstance.defaults);
      window.location.assign("/");
    } catch (e) {
      console.log(e);
    }
  } */
  const isAuthenticated = !!localStorage.getItem('access_token');
  const handleLogout = async () => {
    try {
        // Optional: Make a request to blacklist the token
        await axiosInstance.post('/blacklist/', {
            refresh_token: localStorage.getItem('refresh_token'),
        });
        // Remove items from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('email');

        // Optionally reset the default Authorization header
        axiosInstance.defaults.headers['Authorization'] = null;

        // Redirect to the home page (/python) where the login option should be visible
        window.location.assign('/python');
    } catch (e) {
        console.error("Logout failed:", e);
    }
  };
  const location = useLocation();
  return (
    <div className="container-2">
    <header>
         <div className="col-md-3">
         <h1><a className="logo" href="https://9faqs.com/python">9FAQs<sup>α</sup> <span className="sub-logo">| PythonFAQs</span><br />
          <p className="Versionss"> (β version to be launched shortly)</p>
          </a></h1>

          </div>
          <div className="img-logo" style={{ width: "60%",}}>
             <img src ={"/9faqs_head.png"} />
          </div>

        <div className="tagline" >
            <p>Best & Easiest Way to Self-Master Python</p>
            <div className="menu">
                <Link to ="/python" className={`menu-item ${location.pathname === '/python' ? 'active' : ''}`}>
                 <strong>FAQS</strong>
                </Link>
                <Link to ="/training" className={`menu-item ${location.pathname === '/training' ? 'active' : ''}`}>
                 <strong>PyTraining</strong>
                </Link>
                {isAuthenticated && (
                               <>   
                                    <Link to="/python" className="menu-item" onClick={handleLogout} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faSignOutAlt} />  {/* Use only the icon for logout */}
                                     </Link>
                              </>
                            )}
            </div>
          </div>
        <div className="tagline">
            <Link to="/python">
        <FontAwesomeIcon icon={faHome} />
        </Link>
       </div>   
                </header>
    </div>
  );
};


export default Header;
