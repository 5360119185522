import React from 'react';
import ReactDOM from 'react-dom';
import "./all.css";
import { Header } from './Header';
import {useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { shuffle } from "lodash";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Footer from './Footer';
import emailjs from "emailjs-com";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
import Swal from "sweetalert2";


const SERVICE_ID = "service_wdjh4a7";
const TEMPLATE_ID = "template_7q787io";
const USER_ID = "Z6Nwje68HcpD1-6Ii";
const Report = () => {
    const location = useLocation();
    
    const [tagsdata, setTagsdata] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        report: ""
    });
    const isEmailValid = (email) => {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
    useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []);
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const sendemailform = (e) => {
    e.preventDefault();
    if (formData.name.trim().length < 4 || formData.report.trim().length < 4 || !isEmailValid(formData.email)) {
        Swal.fire({
            icon: "error",
            title: "Please fill in all fields with valid data",
        });
        return;
    }
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
}
    const question_url = `https://9faqs.com/python/${location.state.primary_tag}/${location.state.random_id}`;
    return (
     <div className="App">
     <Header />
    <div class="row">
     <div class="col-md-2"></div>
     <div class="col-md-8">
         <main>

            <form action method="POST" onSubmit={sendemailform}>
                <div className="submit-link-row">
                <label htmlFor="id_question_url">question_url:</label>
                <br />
                <input
                   type="text"
                   name="question_url"
                   required
                   value={`https://9faqs.com/python/${location.state.primary_tag}/${location.state.random_id}`}
                   id="id_question_url"
                    />
                </div> 
                <input
                type="hidden"
                name="question"
                required
                value={location.state.question_title}
                id="id_question"
                />

                {/* Hidden input for code if it exists */}
                {location.state.code && (
                <input
                    type="hidden"
                    required
                    name="code"
                    value={location.state.code}
                    id="id_code"
                   />
              )} 
 
               <div class="submit-link-row">
                    <label for="id_name">Name:</label>
                    <br></br>
                    <input type="text" name="name" required id="id_name" onChange={handleInputChange} value={formData.name}/>
               </div>
               <div class="submit-link-row">
                    <label for="id_email">Email:</label>
                    <br></br>
                    <input type="email" name="email" required id="id_email"/>
               </div>
               <div class="submit-link-row">
                    <label for="id_report">Report:</label>
                    <br></br>
                    <input type="text" name="report" required id="id_report" onChange={handleInputChange} value={formData.report}/>
               </div>
               <button type="submit" name="submit" class="waves-effect waves-light btn submit-button pink mt-30">Send Report</button>
            </form>
         </main>
      </div> 
   </div> 
   <Footer />
   </div>
   );
};

export default Report
