import React, { useEffect } from 'react';

const CanonicalTag = ({ url }) => {
  useEffect(() => {
    // Create a new link element for the canonical tag
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = url;

    // Find the HTML head element and append the link element to it
    const head = document.querySelector('head');
    head.appendChild(link);

    // Clean up function to remove the link element when the component unmounts
    return () => {
      head.removeChild(link);
    };
  }, [url]);

  // Return null because this component doesn't render any visible UI
  return null;
};

export default CanonicalTag;

