// src/components/NotFound.js

import React from "react";
import "./all.css";
import { Header } from './Header';
import { useEffect, useState } from "react";
import { shuffle } from "lodash";
import emailjs from "emailjs-com";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
import Swal from "sweetalert2";

const NotFound = () => {
     const [tagsdata, setTagsdata] = useState([]);
  const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        feedback: ""
    });
     useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []);
    return (
   <div className="App">
  <Header />
  <div class="row">
     <div class="col-md-2"></div>
     <div class="col-md-8">
        <div className="NotFound">
            <h2>Page Not Found</h2>
            <p>The page you are looking for does not exist.</p>
        </div>
    </div>
    </div>
    <div className="container-footer">
        <footer>
          {/*<div>
            <p>copyright@9faqs.com</p>
          </div>*/}
          <span className="python-tags"> Tags:
          </span>
          {tagsdata[1]?.tags?.map((tag_name, i) => (
            <span className="python-tags"
              key={tag_name.id}
            > 
              {tag_name.name}
              {i < tagsdata[1].tags.length - 1 ? " | " : null}
            </span>
          ))}
         <div></div>
          <br />
        <div className="footer-about">
        <a className="footer-about" href='/about'>About </a>  &nbsp;|
        <a className="footer-about" href='https://9faqs.com/feedback'>&nbsp;  Feedback</a> | copyright@9faqs.com
        </div>

        </footer>
      </div>
   </div>
    );
};

export default NotFound;
