import React, { useState } from "react";
import axiosInstance from "../axioApi";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "email") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        }
    };

    const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await axiosInstance.post('/token/obtain/', {
            email: email,
            password: password
        });

        if (response.status === 200) {
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            localStorage.setItem("email", email);

            // Navigate to /python after successful login
            navigate('/python');
        } else {
            setError("Login failed. Please check your credentials.");
        }
    } catch (error) {
        setError("An error occurred during login. Please try again.");
    }
};
    return (
        <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleSubmit}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign In</h3>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="form-group mt-3">
                        <label>Email address</label>
                        <input
                            className="form-control mt-1"
                            name="email"
                            type="text"
                            value={email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                            className="form-control mt-1"
                            name="password"
                            type="password"
                            value={password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <br />
                    <div>
                        <button style={{ width: "10% !important" }} type="submit" className="btn btn-secondary">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;

