import './AddQuestion.css';
import React, { useState, useEffect } from "react";
import API from "./API.js";
import "./all.css";
import Swal from "sweetalert2";
import { Header } from "./Header";
import Footer from './Footer';
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const AddQuestion = () => {
  const [inputList, setInputList] = useState([{ answer: "", is_correct: false }]);
  const [tagsList, setTagsList] = useState([{ tag_name: "" }]);
  const [tagsdata, setTagsdata] = useState([]);
  
  useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []);

  const data_ = {
    question_title: "",
    code: "",
    author: localStorage.getItem("email"),
    explanation: "",
    mlevel: 10,
    is_active: false,
    answers_g: [{ answer: "", is_correct: false }],
    tags_g: [{ tag_name: "" }],
  };

  const [totalq, setTotalq] = useState(data_);

  const handlesubmit = (e) => {
    e.preventDefault();
    API.post("/", totalq).then(
      (result) => {
        Swal.fire({
          icon: "success",
          title: "Question added successfully",
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Ooops, Enter the fields correctly",
          text: error.request.responseText,
        });
      }
    );
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setTotalq({ ...totalq, answers_g: inputList });
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { answer: "", is_correct: false }]);
  };

  const handletagsInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...tagsList];
    list[index][name] = value;
    setTagsList(list);
    setTotalq({ ...totalq, tags_g: tagsList });
  };

  const handletagsRemoveClick = (index) => {
    const list = [...tagsList];
    list.splice(index, 1);
    setTagsList(list);
  };

  const handletagsAddClick = () => {
    setTagsList([...tagsList, { tag_name: "" }]);
  };
return (
    <div className="App">
      <Header />
      <div className="container">
        <h2 className="form-heading">Add Question</h2>
        
        <div className="form-container">
          {localStorage.refresh_token ? (
            <form className="form-class" onSubmit={handlesubmit}>
              
              <label htmlFor="question">Question:</label>
              <textarea
                id="question"
                value={totalq.question_title}
                required
                rows="5"
                cols="70"
                onChange={(e) => setTotalq({ ...totalq, question_title: e.target.value })}
              />
              
              <label htmlFor="code">Code:</label>
              <textarea
                id="code"
                rows="5"
                cols="70"
                value={totalq.code}
                onChange={(e) => setTotalq({ ...totalq, code: e.target.value })}
              />
              
              <label htmlFor="author">Author:</label>
              <input
                id="author"
                key={localStorage.email}
                type="text"
                value={totalq.author}
                readOnly
              />
              
              <label htmlFor="is_active">Is Active:</label>
              <input
                id="is_active"
                type="checkbox"
                defaultChecked={false}
                onChange={(e) => setTotalq({ ...totalq, is_active: e.target.checked })}
              />
              
              <label htmlFor="difficulty">Difficulty Level:</label>
              <input
                id="difficulty"
                type="number"
                name="mlevel"
                required
                value={totalq.mlevel}
                onChange={(e) => setTotalq({ ...totalq, mlevel: e.target.value })}
              />
              
              <label htmlFor="explanation">Explanation:</label>
              <textarea
                id="explanation"
                rows="5"
                cols="70"
                required
                value={totalq.explanation}
                onChange={(e) => setTotalq({ ...totalq, explanation: e.target.value })}
              />

              <h4>Answer Section</h4>
              {inputList.map((x, i) => (
                <div key={i} className="answer-section">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Answer</th>
                        <th>Is Correct</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea
                            name="answer"
                            rows="5"
                            cols="70"
                            required
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            name="is_correct"
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </td>
                        <td>
                          {inputList.length !== 1 && (
                            <button onClick={() => handleRemoveClick(i)}>Remove</button>
                          )}
                          {i < 3 && inputList.length - 1 === i && (
                            <button onClick={handleAddClick}>Add</button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}

              <h4>Tags Section</h4>
              {tagsList.map((x, i) => (
                <div key={i} className="tags-section">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Tag</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea
                            rows="3"
                            cols="50"
                            name="tag_name"
                            value={x.tag_name}
                            onChange={(e) => handletagsInputChange(e, i)}
                          />
                        </td>
                        <td>
                          {tagsList.length !== 1 && (
                            <button onClick={() => handletagsRemoveClick(i)}>Remove</button>
                          )}
                          {i < 3 && tagsList.length - 1 === i && (
                            <button onClick={handletagsAddClick}>Add</button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}

              <button type="submit" className="submit-button">Submit</button>
            </form>
          ) : (
            <p>Login to the website to add a question</p>
          )}
        </div>
        </div>
        <Footer />
    </div>
  );
};

export default AddQuestion;
