import axios from "axios";

export default axios.create({
  baseURL: "https://js.pythonfaqs.com/QWERASDF/",
  timeout: 100000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

